<template>
  <AdminToast/>
  <div class="grid">
    <div class="col-12">
      <Button label="Yeni Fiyat Ekle" icon="pi pi-plus" @click="addNew"></Button>
      </div>
    <div class="col-12">
      <Panel>
        <template #header>
          <b>Metal Bilgileri</b>
        </template>
        <div class="p-fluid grid">
          <div class="field col-12 md:col-12">
            <label for="resourceId">Metal</label>
            <Dropdown id="resourceId" v-model="dataItem.resourceId" :options="resources" option-label="name" option-value="id" placeholder="Metal Seçin" :filter="true" filterPlaceholder="Metal Ara" />
          </div>
          <div class="field col-12 md:col-12">
            <label for="date">Tarih</label>
            <Datepicker id="date" v-model="dataItem.date" locale="tr" cancelText="Vazgeç" selectText="Seç" nowButton="Şimdi" format="dd.MM.yyy"></Datepicker>

          </div>
          <div class="field col-12 md:col-12">
            <label for="sortOrder">Fiyat</label>
            <InputText id="sortOrder" type="text" v-model="dataItem.price"/>
          </div>
          <div class="field col-12 md:col-12">
            <Button label="Kaydet" class="p-button-success p-button-raised p-button-rounded" @click="save"></Button>
          </div>
          <div class="field col-12 md:col-12">
            <Button label="Sil" class="p-button-danger p-button-raised p-button-rounded" @click="this.deleteConfirmationDialogVisibility= true" v-if="resourceDataId>0"></Button>
          </div>
        </div>
      </Panel>
      <Dialog
          v-model:visible="deleteConfirmationDialogVisibility"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
          <span>Bu Kaynağı silmek istediğinize eminmisiniz?</span>
        </div>
        <template #footer>
          <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteConfirmationDialogVisibility = false"
          />
          <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedData"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import AdminToast from "@/components/shared/toast/adminToast";
import {showErrorMessage} from "@/components/shared/toast/toastFunction";
import {showSuccessMessage} from "../../components/shared/toast/toastFunction";
import ResourceService from "../../services/resourceService";
import ResourceDataService from "../../services/resourceDataService";

export default {
  _service: null,
  _resourceService: null,
  components: {AdminToast},
  created() {
    this._service = new ResourceDataService();
    this._resourceService = new ResourceService();
    this.resourceDataId = this.$route.params.resourceDataId;
  },
  async mounted() {
    this._resourceService.getAllResources().then(async response => {
      this.resources = response.data;
      await this.getResourceData(this.resourceDataId);
    })

  },

  data() {
    return {
      returnField:"",
      resources: [],
      deleteConfirmationDialogVisibility:false,
      resourceDataId:0,
      dataItem: {
        name: "",
        isActive: "",
      }
    }
  },
  methods: {
    async save() {
      const updateResponse = await this._service.updateResourceData(this.resourceDataId, this.dataItem);
      if(updateResponse.isSuccess)
      {
        showSuccessMessage(this, "Kaynak başarı ile güncellendi");
      }
      else {
        showErrorMessage(this, "Kaynak güncellemede sorun ile karşılaşıldı");
      }
    },

    async deleteSelectedData() {
      this.deleteConfirmationDialogVisibility = false;
      const deleteResponse = await this._service.deleteById(this.resourceDataId);
      if (deleteResponse.isSuccess) {
        setTimeout( async () => await this.$router.push({name: "resourceDataList"}), 2000);
        showSuccessMessage(this, deleteResponse.message);


      } else {
        showErrorMessage(this, deleteResponse.message);
      }
    },
    async getResourceData(resourceDataId) {
      let getResponse = await this._service.getResourceDataById(resourceDataId);
      if(getResponse.isSuccess) {
        this.dataItem.resourceId = getResponse.data.resourceId;
        this.dataItem.date = getResponse.data.date;
        this.dataItem.price = getResponse.data.price;
      }
      else {
        showErrorMessage(this, getResponse.message);
      }
    },
    addNew() {
      this.$router.push({name: "resourceDataAdd"});
    },
  }
}
</script>